<template>
  <!-- :style="{ height: '62%','background-color':'#F4F4F4' }" -->
  <van-popup
    v-model="show"
    closeable
    round
    @close="close"
    position="bottom"
    style="background-color:#fff;padding-bottom:50px;padding-top:0px"
  >
    <div
      class="points-main"
      style="background-color:#F4F4F4;"
      ref='datePopper'
    >

      <div class="points-title">
        填写信息
      </div>
      <van-form>
        <van-field
          v-if="userShow"
          v-model="username"
          :disabled='smsCheck'
          clearable
          name="姓名"
          label="姓名"
          placeholder="收货人姓名"
        />
        <van-field
          v-if="userShow"
          v-model="usertel"
          :disabled='smsCheck'
          clearable
          name="电话"
          label="电话"
          placeholder="收货人电话"
        />
        <van-field
          v-if="userShow"
          :disabled='smsCheck'
          readonly
          clickable
          name="area"
          :value="uservalue"
          label="地区"
          placeholder="省市区"
          @click="showArea = true"
        />
        <van-popup
          v-model="showArea"
          position="bottom"
        >
          <van-cascader
            title="请选择所在地区"
            :options="options"
            @close="showArea = false"
            @finish="onFinish"
          />
          <!-- <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          /> -->
        </van-popup>

        <van-popover
          v-model="showPopover"
          :actions="searchResult"
          @select="onSelect"
          style="width:100%"
        >
          <template #reference>
            <van-field
              v-if="userShow"
              v-model="userdetail"
              rows="2"
              :disabled='smsCheck'
              autosize
              clearable
              label="详细"
              type="textarea"
              id='textarea'
              placeholder="街道门牌/楼层房间号等信息"
              @input='onDetailInput'
            />
          </template>
        </van-popover>
        <!-- <van-field
          v-if="userShow"
          v-model="userdetail"
          rows="1"
          autosize
          clearable
          label="详细"
          type="textarea"
          placeholder="街道门牌/楼层房间号等信息"
        /> -->
        <van-field
          v-if="!userShow"
          v-model="curInfo.username"
          readonly
          clearable
          name="姓名"
          label="收货人姓名"
          placeholder="收货人姓名"
        />
        <van-field
          v-if="!userShow"
          v-model="curInfo.usertel"
          readonly
          clearable
          name="电话"
          label="收货人电话"
          placeholder="收货人电话"
        />
        <van-field
          v-if="!userShow"
          v-model="curInfo.address"
          readonly
          rows="1"
          type="textarea"
          clearable
          name="收货地址"
          label="收货地址"
          placeholder="收货地址"
        />
        <div style="margin-top:16px ">
          <van-cell title="支付方式">
            <template #right-icon>
              <span style="color:#F42122">{{ retailPrice }}积分+0元</span>
            </template>
          </van-cell>
          <van-cell
            title="运费"
            value="包邮"
          />
        </div>
        <div style="padding: 16px 10% 6% 10%; text-align: center; margin:16px 0; background: #fff">

          <van-field
            v-model="phoneCode"
            center
            clearable
            type="digit"
            maxlength="6"
            placeholder="请输入短信验证码"
            style="border-radius: 50px; background: #f6f6f6"
          >
            <template #button>
              <span
                :style="{ color: variables.textColor }"
                style="cursor: pointer;"
                @click="getSendPaySms2()"
                v-html="integralCount"
              >发送验证码</span>
            </template>
          </van-field>
        </div>

      </van-form>
    </div>
    <div
      class="points-address-btn"
      style="position: fixed; bottom: 0;left:0;right:0; width: 100%"
    >
      <van-button
        block
        color="linear-gradient(180deg, #F67743 0%, #F75D22 100%)"
        @click="onExSubmit"
      >
        确认兑换
      </van-button>
      <div id="allmap"></div>
    </div>

  </van-popup>
</template>

<script>
import areaList from "@/utils/area.js";
import { Toast } from "vant";
import validateRule from "@/utils/validateRule";
import API from "../../api/http";
import area from "@/utils/pcas-code.json";
import variables from "@/styles/variables.scss";
import { getRegionIu } from "@/utils/common.js";

//立即兑换组件
export default {
  props: {
    integralPopup: {
      type: Boolean,
      default: false,
    },
    retailPrice: {
      type: Number,
      default: 0,
    },
    userShow: {
      type: Boolean,
      default: true,
    },
    goodId: {
      default: "",
    },
    smsSubmitType: {
      type: Number,
      default: 1,
    },
    checkGoodls: {
      type: Object,
      default: () => {
        return {};
      },
    },
    orderSnId: {
      default: "",
    },
    addressData: {
      default: "",
    },
  },
  data() {
    return {
      show: false,
      areaList,

      username: "",
      usertel: "",
      uservalue: "",
      userdetail: "",
      sendPopup: false,
      showArea: false,
      phoneCode: "",
      timer: "",
      integralCount: "发送验证码",
      street: "",
      city: "", //市
      county: "", //区
      province: "", //省
      areaCode: "",
      showPopover: false,
      searchResult: [],
      timerOut: null,
      chosenAddressId: "",
      checkedAddress: "",
      orderId: "",
      smsCheck: false,
      curInfo: {
        username: "",
        usertel: "",
        address: "",
      },
      options: [],
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  watch: {
    integralPopup(val) {
      this.show = val;
    },
    goodId: {
      handler(newValue, oldValue) {
        if (newValue !== "") {
          if (oldValue !== "" && newValue !== oldValue) {
            console.log("goodId-newValue", newValue, oldValue);
            console.log("goodId-oldValue", oldValue);
            this.setPage();
          }
        }
      },
      deep: true,
    },
    addressData(val) {
      //继续支付
      console.log(val);
      this.setValue();
    },
  },
  created() {
    this.show = this.integralPopup;
    this.getProvince2();
  },
  mounted() {
    document.addEventListener("mouseup", this.setHeadPosition, true);
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", this.setHeadPosition);
    // window.removeEventListener('mouseup', e => {}, true)
  },
  methods: {
    setHeadPosition(e) {
      const that = this;
      console.log("points-pop", e.target.id);
      if (that.$refs.datePopper) {
        // 点击其他地方关闭 popover气泡
        if (e.target.id !== "textarea") {
          that.showPopover = false;
        }
      }
    },
    getProvince2() {
      let list = area;
      this.options = list.map((item) => {
        return {
          text: item.name,
          value: item.code,
          children: item.children.map((c) => {
            return {
              text: c.name,
              value: c.code,
              children: c.children.map((m) => {
                let clIst = [
                  ...c.children,
                  {
                    name: "其他",
                    code: c.code + 99,
                    children: [],
                  },
                ];

                return {
                  text: m.name,
                  value: m.code,
                  children: clIst
                    .map((t) => {
                      return {
                        text: t.name,
                        value: t.code,
                      };
                    })
                    .concat([
                      {
                        text: "其他",
                        value:
                          m.code +
                          (m.children.length + 1 > 10
                            ? m.children.length + 1
                            : 0 + m.children.length + 1),
                      },
                    ]),
                };
              }),
            };
          }),
        };
      });
    },
    setValue() {
      this.curInfo = this.addressData;
    },
    setPage() {
      this.orderId = "";
      this.phoneCode = "";
      this.smsCheck = false;
      clearInterval(this.timer); // 清除定时器
      this.integralCount = "发送验证码";
      this.timer = null;
    },
    close() {
      this.$emit("update:integralPopup", false);
    },
    onDetailInput(val) {
      if (val.trim() && this.province) {
        if (this.timerOut) {
          clearTimeout(this.timerOut);
          this.timerOut = null;
        }
        this.timerOut = setTimeout(() => {
          this.getAddress(val);
        }, 500);
      } else {
        clearTimeout(this.timerOut);
        this.timerOut = null;
        this.searchResult = [];
      }
    },
    getAddress(val) {
      API.setAddressQuery({
        city: this.city,
        keywords: val,
        userId: this.userId,
      }).then((res) => {
        console.log(res);
        this.showPopover = true;
        this.searchResult = res.data.list.map((item) => {
          return {
            text: item,
            icon: "location-o",
            className: "action-dom",
          };
        });
      });
      // var myGeo = new BMap.Geocoder();
      // let that = this;
      // myGeo.getPoint(
      //   val,
      //   function (point) {
      //     if (point) {
      //       console.log("ll", point);
      //       let map = new BMap.Map("allmap");
      //       map.centerAndZoom(new BMap.Point(point.lng, point.lat), 11);
      //       that.getSeach(map, val);
      //     }
      //   },
      //   this.province
      // );
    },
    getSeach(map, val) {
      // let map = new BMap.Map("allmap");
      // map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 11);
      // //创建地址解析器实例
      // // 将地址解析结果显示在地图上，并调整地图视野
      let that = this;
      var options = {
        renderOptions: { map: map },
        onSearchComplete: function (results) {
          console.log(1122);
          if (local.getStatus() === BMAP_STATUS_SUCCESS) {
            that.searchResult = [];
            that.showPopover = true;
            for (var i = 0; i <= results.getCurrentNumPois(); i++) {
              let m = results.getPoi(i);
              if (m) {
                let index = m.address.indexOf(that.county) || 0;
                let len = that.county.length;
                that.searchResult.push({
                  text: m.address.substring(index + len) + " " + m.title || "",
                  icon: "location-o",
                  className: "action-dom",
                });
              }
            }
            console.log("kkk", that.searchResult);
          } else {
            Toast("您输入的地址没有解析到结果！");
          }
        },
      };
      var local = new BMap.LocalSearch(map, options);
      // let name = this.city + this.county + val;
      let name = this.city + this.county + this.street + val;
      local.search(name);
    },
    onSelect(val) {
      //选中
      this.userdetail = val.text;
    },
    async getSendPaySms2() {
      if (!this.checkInfo()) {
        return;
      }
      let obj = {};
      console.log("otder", this.orderId);
      if (this.userShow) {
        if (!this.orderId) {
          await this.addAddress();

          if (this.smsSubmitType === 1) {
            await this.checkoutCart();
            try {
              await this.orderSubmit();
            } catch {
              return;
            }
          } else {
            try {
              await this.orderFastSubmit();
            } catch {
              return;
            }
          }
        }

        //  smsSubmitType: this.smsSubmitType,
        // addressId: this.chosenAddressId,
        obj = {
          orderId: Number(this.orderId),
        };
      } else {
        obj = {
          orderId: Number(this.orderSnId),
        };
      }

      // if (this.smsSubmitType === 1) {
      //   //购物车 兑换时，传递 商品购物车id
      //   obj.cartId = this.goodId;
      // } else {
      //   //商城或者详情 立即购买时，传递商品产品id
      //   obj.productId = this.checkGoodls.productId;
      // }
      let TIME_COUNT = 60; // 更改倒计时时间
      if (!this.timer) {
        API.postOrderSmsSend(obj)
          .then((res) => {
            Toast("发送成功");
            this.smsCheck = true;
            // this.$emit("orderSubmitPop");
            this.timer = setInterval(() => {
              if (TIME_COUNT > 0 && TIME_COUNT <= 60) {
                TIME_COUNT--;
                this.integralCount = `${TIME_COUNT}s`;
              } else {
                clearInterval(this.timer); // 清除定时器
                this.integralCount = "重新发送";
                this.timer = null;
              }
            }, 1000);
          })
          .catch((err) => {
            Toast(err.errmsg);
          });
        // 发送验证码
        // Toast.loading({
        //   message: "等待中...",
        //   forbidClick: true,
        //   duration: 0,
        // });
      }
    },
    addAddress() {
      //没有地址时，添加的地址调用 地址添加接口，以获取地址id
      // phoneCode: this.phoneCode,
      const obj = {
        id: 0,
        isDefault: false,
        name: this.username.trim(),
        tel: this.usertel.trim(),
        uservalue: this.uservalue,
        addressDetail: this.userdetail,

        areaCode: this.areaCode,
        city: this.city, //市
        county: this.county, //区
        province: this.province, //省
      };
      return API.postAddressAdd(obj).then((res) => {
        this.chosenAddressId = res.data;
      });
    },
    checkoutCart() {
      return API.checkoutCart({
        cartId: this.goodId,
        addressId: this.chosenAddressId,
      }).then((res) => {
        this.checkedAddress = res.data.checkedAddress;
      });
    },
    orderSubmit() {
      return API.orderSubmit({
        cartId: this.goodId,
        addressId: this.chosenAddressId,
        iu: getRegionIu(),
      }).then((res) => {
        this.orderId = res.data.orderId;
      });
    },
    orderFastSubmit() {
      return API.orderFastSubmit({
        goodId: this.checkGoodls.goodsId,
        addressId: this.chosenAddressId,
        productId: this.checkGoodls.productId,
        number: this.checkGoodls.number,
        iu: getRegionIu(),
      }).then((res) => {
        this.orderId = res.data.orderId;
      });
      // .catch((err) => {
      //   if (
      //     err.errno === 728 &&
      //     err.errmsg.indexOf("该商品存在待支付订单") !== -1
      //   ) {
      //     this.$router.push({
      //       path: "/order",
      //       query: {
      //         active: "1",
      //       },
      //     });
      //   }
      // });
    },
    onExSubmit() {
      if (!this.checkInfo()) {
        return;
      }

      // if (this.integralCount === "发送验证码") {
      //   Toast("请获取验证码");
      //   return;
      // }
      if (!this.phoneCode.trim()) {
        Toast("请输入验证码");
        return;
      }
      let map = {
        paysmsCode: this.phoneCode.trim(),
      };
      if (this.userShow) {
        map.orderId = Number(this.orderId);
      } else {
        map.orderId = Number(this.orderSnId);
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      API.postMobilePay(map).then((res) => {
        Toast.clear();
        Toast("兑换成功");
        this.setPage();
        this.$emit("onExSubmit");
      });

      //
      // this.$emit("onExSubmit", {
      //   name: this.username,
      //   tel: this.usertel,
      //   uservalue: this.uservalue,
      //   addressDetail: this.userdetail,
      //   phoneCode: this.phoneCode,
      //   areaCode: this.areaCode,
      //   city: this.city, //市
      //   county: this.county, //区
      //   province: this.province, //省
      // });
    },
    onFinish({ selectedOptions }) {
      console.log(selectedOptions);
      // this.show = false;
      this.uservalue = selectedOptions.map((option) => option.text).join("/");

      this.province = selectedOptions[0].text;

      this.city = selectedOptions[1].text;
      this.county = selectedOptions[2].text;
      this.street = selectedOptions[3].text;
      this.areaCode = selectedOptions[2].value;
      this.userdetail = "";
      this.showArea = false;
    },
    onConfirm(values) {
      this.areaCode = values[2].code;
      this.uservalue = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.province = values[0].name;
      this.city = values[1].name;
      this.county = values[2].name;
      this.showArea = false;
      this.userdetail = "";
    },
    checkInfo() {
      if (this.userShow) {
        if (!this.username.trim()) {
          Toast("请输入收货人姓名");
          return false;
        }
        if (!this.usertel.trim()) {
          Toast("请输入收货人电话");
          return false;
        }
        if (!validateRule.checkPhoneAndTel(this.usertel)) {
          //手机号格式不正确
          Toast("手机号码输入错误");
          return;
        }
        if (!this.uservalue.trim()) {
          Toast("请选择地区");
          return false;
        }
        if (!this.userdetail.trim()) {
          Toast("请输入详细地址");
          return false;
        }
        return true;
      }
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.points-title {
  font-size: 18px;
  color: #494949;
  background-color: #fff;
  padding: 16px;
}
</style>
